import { ManualIncidentCategoriesQuery } from '@/graphql/generated/operations'
import { getIncidentCategoryOptions } from '@/utils/forms/optionBuilders'
import { sortOptions } from '@/utils/forms/optionHelpers'

const STRING_REGEX = /^[a-zA-Z0-9_\s('!&@/$")-=:]*$/
export const isDeviceHealthSelected = (
  incidentCategoriesData: ManualIncidentCategoriesQuery,
  incidentCategoryId: string
): boolean => {
  const incidentCategories = sortOptions(
    getIncidentCategoryOptions(incidentCategoriesData)
  )
  return (
    incidentCategories?.find((i) => i.value === incidentCategoryId)?.label ===
    'Device Health'
  )
}

export const nameRules = {
  required: 'Incident name is required',
  maxLength: {
    value: 255,
    message: 'Incident name should have less than 256 characters',
  },
  pattern: {
    value: STRING_REGEX,
    message: 'Insert only alphanumeric characters and spaces',
  },
}

export const descriptionRules = {
  required: 'Incident description is required',
  maxLength: {
    value: 255,
    message: 'Incident description should have less than 256 characters',
  },
  pattern: {
    value: STRING_REGEX,
    message: 'Insert only alphanumeric characters and spaces',
  },
}
